import React from "react";
import MissionControl from "./sentia_views/customers/MissionControl";

// import TenantList from "./sentia_views/customers/TenantList";
const CustomerList = React.lazy(() => import("./sentia_views/customers/TenantList"));
const CustomerAzureAD = React.lazy(() => import("./sentia_views/customers/AzureAD"));
const CustomerOverview = React.lazy(() => import("./sentia_views/customers/Overview"));
const CustomerPipe = React.lazy(() => import("./sentia_views/customers/Pipelines"));
const BicepParamPipeline = React.lazy(() => import("./sentia_views/customers/BPPipelines"));
const CustomerImport = React.lazy(() => import("./sentia_views/customers/CustomerImport"));
const CustomerQualityCheck = React.lazy(() => import("./sentia_views/customers/QualityCheck"));
const CustomerIntegrationFlow = React.lazy(() => import("./sentia_views/customers/IntegrationFlow"));
const GlobalSettingsComponent = React.lazy(() => import("./sentia_views/settings/Global"));
const StatusComponent = React.lazy(() => import("./sentia_views/Status"));
const Dashboard = React.lazy(() => import("./sentia_views/Dashboard"));
const ResourceDefinitions = React.lazy(() => import("./sentia_views/ResourceDefinitions"));

const DocsGenerator = React.lazy(() => import("./sentia_views/customers/DocsGenerator"));
const KeyVault = React.lazy(() => import("./sentia_views/customers/KeyVault"));
const MonitoringConfigGenerator = React.lazy(() => import("./sentia_views/customers/MonitoringConfigGenerator"));
const MonitoringSuppression = React.lazy(() => import("./sentia_views/customers/MonitoringSuppression"));

const routes = [
  // { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", exact: true, name: "Dashboard", component: <Dashboard /> },
  { path: "/status", exact: true, name: "Status", component: <StatusComponent /> },
  { path: "/settings", exact: true, name: "Settings", component: <GlobalSettingsComponent /> },
  { path: "/customer", exact: true, name: "Customers", component: <CustomerList /> },
  { path: "/resourcedefinitions", exact: true, name: "Resource Definitions", component: <ResourceDefinitions /> },
  {
    path: "/resourcedefinitions/:resourceDefId",
    exact: true,
    name: "Resource Definitions",
    component: <ResourceDefinitions />,
  },
  { path: "/customerimport", exact: true, name: "Import", component: <CustomerImport /> },
  { path: "/customer/:customerId/azuread", exact: true, name: "AzureAD", component: <CustomerAzureAD /> },
  { path: "/customer/:customerId/overview", exact: true, name: "Overview", component: <CustomerOverview /> },
  { path: "/customer/:customerId/pipeline", exact: true, name: "Pipeline", component: <CustomerPipe /> },
  // eslint-disable-next-line prettier/prettier
  { path: "/customer/:customerId/modernpipeline", exact: true, name: "Modern Pipeline", component: <BicepParamPipeline /> },
  { path: "/customer/:customerId/missioncontrol", exact: true, name: "Mission Control", component: <MissionControl /> },
  // eslint-disable-next-line prettier/prettier
  // { path: "/customer/:customerId/modernmissioncontrol", exact: true, name: "Modern Mission Control", component: <ModernMissionControl /> },
  { path: "/customer/:customerId/qc", exact: true, name: "Quality Check", component: <CustomerQualityCheck /> },
  { path: "/customer/:customerId/if", exact: true, name: "IntegrationFlow", component: <CustomerIntegrationFlow /> },
  { path: "/customer/:customerId/docsgenerator", exact: true, name: "Docs Generator", component: <DocsGenerator /> },
  { path: "/customer/:customerId/keyvault", exact: true, name: "Key Vault", component: <KeyVault /> },
  {
    path: "/customer/:customerId/monitoringconfiggenerator",
    exact: true,
    name: "Monitoring Config Generator",
    component: <MonitoringConfigGenerator />,
  },
  {
    path: "/customer/:customerId/monitoringsuppression",
    exact: true,
    name: "Monitoring Config Generator",
    component: <MonitoringSuppression />,
  },
];

export { routes };
